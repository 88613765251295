import { render, staticRenderFns } from "./PreviewList.vue?vue&type=template&id=245f7246&"
import script from "./PreviewList.vue?vue&type=script&lang=js&"
export * from "./PreviewList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/giftlist/the-gift-list/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('245f7246')) {
      api.createRecord('245f7246', component.options)
    } else {
      api.reload('245f7246', component.options)
    }
    module.hot.accept("./PreviewList.vue?vue&type=template&id=245f7246&", function () {
      api.rerender('245f7246', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "static_src/js/app-vue/events/PreviewList.vue"
export default component.exports