<template>
    <div>
        <form method="post" @submit.prevent="createEventSubmit">

            <h4 class="common-title-section"Nombre de la lista<div class="ico-heart-title"></div></h4>

            <div v-html="csrf_token"></div>

            <div>

                <div class="wrapper-create-event-type">
                    <div class="wrapper-event-type wrapper-odd">

                        <input type="radio" hidden :value="event_type.SHOWER.id"
                                       name="event_type_id" :id="'event_type_'+event_type.SHOWER.id"
                                       class="custom-check-radio"
                                       v-model.number="event_type_id" v-validate="'required'">

                        <label :for="'event_type_'+event_type.SHOWER.id" class=" container-event-type">
                            <div class="container-icon-event-type">
                                <div class="icon-event-type icon-shower"></div>
                            </div>

                            <div class="common-title  title-no-padding">{{ event_type.SHOWER.name }}</div>
                            <span class="fake-check-radio"></span>
                        </label>
                    </div>

                    <div class="wrapper-event-type wrapper-even">
                        <input type="radio" hidden :value="event_type.WEDDING.id"
                                       name="event_type_id" :id="'event_type_'+event_type.WEDDING.id"
                                       class="custom-check-radio"
                                       v-model.number="event_type_id" v-validate="'required'">

                        <label :for="'event_type_'+event_type.WEDDING.id" class=" container-event-type">
                            <div class="container-icon-event-type">
                                <div class="icon-event-type icon-wedding"></div>
                            </div>

                            <div class="common-title  title-no-padding">{{ event_type.WEDDING.name }}</div>

                            <span class="fake-check-radio"></span>
                        </label>
                    </div>

                    <div class="separator-event-types"></div>

                    <div class="wrapper-event-type wrapper-odd">
                        <input type="radio" hidden :value="event_type.BABY_SHOWER.id"
                                       name="event_type_id" :id="'event_type_'+event_type.BABY_SHOWER.id"
                                       class="custom-check-radio"
                                       v-model.number="event_type_id" v-validate="'required'">

                        <label :for="'event_type_'+event_type.BABY_SHOWER.id" class=" container-event-type">
                            <div class="container-icon-event-type">
                                <div class="icon-event-type icon-baby-shower"></div>
                            </div>

                            <div class="common-title  title-no-padding">{{ event_type.BABY_SHOWER.name }}</div>

                            <span class="fake-check-radio"></span>
                        </label>
                    </div>

                    <div class="wrapper-event-type wrapper-even">
                        <input type="radio" hidden :value="event_type.BIRTHDAY.id"
                                       name="event_type_id" :id="'event_type_'+event_type.BIRTHDAY.id"
                                       class="custom-check-radio"
                                       v-model.number="event_type_id" v-validate="'required'">

                        <label :for="'event_type_'+event_type.BIRTHDAY.id" class=" container-event-type">
                            <div class="container-icon-event-type">
                                <div class="icon-event-type icon-birthday"></div>
                            </div>

                            <div class="common-title  title-no-padding">{{ event_type.BIRTHDAY.name }}</div>

                            <span class="fake-check-radio"></span>
                        </label>
                    </div>

                </div>
                <p class="message-error-field" v-if="errors.has('event_type_id')">
                    {{ errors.first('event_type_id') }}
                </p>


                <div class="common-content-field">

                    <label class="common-label-field">NOMBRE DE LA LISTA*</label>
                    <input type="text" name="event_title" class="common-input-text"
                           :placeholder="placeholder_input"
                           v-bind:class="{'error-input-text': errors.has('event_title')}"
                           v-validate="'required|min:2|max:100'"
                           v-model="event_title">

                    <p class="message-error-field" v-if="errors.has('event_title')">
                        {{ errors.first('event_title') }}
                    </p>

                </div>
            </div>
            <div class="common-container-btn content-bnt-and-link flex-mobile">
                <a :href="link_home" class="link-cancel-form">
                   CANCELAR
                </a>

                <button type="submit" class="common-green-btn">
                    SIGUIENTE
                    <span class="arrow-into-btn"></span>
                </button>
            </div>

            <div>
                <p v-if="response_error_message" v-html="response_error_message" class="error-submit-form-message"></p>
                <p v-if="response_success_message" v-html="response_success_message" class="success-submit-form-message"></p>
            </div>

        </form>

    </div>
</template>

<script>

    import * as eventsAPI from '../../api/events'

    export default {
        name: "create-event",
        props: ['csrf_token', 'event_type', 'link_home'],
        data: () =>  {
            return {
                event_type_id: null,
                event_title: null,
                response_error_message: '',
                response_success_message: '',
                placeholder_input: 'Ingresa el nombre de la lista'
            }
        },
        watch: {
            'event_type_id': function (){

                switch(this.event_type_id) {
                    case this.event_type.BABY_SHOWER.id:
                        this.placeholder_input = 'Nombre del bebé';
                        break;
                    case this.event_type.WEDDING.id:
                        this.placeholder_input = 'Michi y Carlos';
                        break;
                    case this.event_type.SHOWER.id:
                        this.placeholder_input = 'Nombre';
                        break;
                    case this.event_type.BIRTHDAY.id:
                        this.placeholder_input = 'Nombre del cumpleañero o cumpleañera';
                        break;
                    default:
                        this.placeholder_input = 'Ingresa el nombre de la lista';
                }
            }
        },
        computed: {
            isFormValid: function() {
                return Object.keys(this.fields).some(key => this.fields[key] && this.fields[key].changed)
                    && Object.keys(this.fields).every(key => this.fields[key] && this.fields[key].valid);
            }
        },
        methods:{
            createEventSubmit: function () {
                this.$validator.validate().then(result => {
                    if (result && this.isFormValid) {
                        eventsAPI.createEvent(
                            this.event_type_id, this.event_title
                        ).then( (response) => {

                            if(response.data.success){
                                this.response_error_message = '';
                                this.response_success_message = response.data.message;

                                setTimeout(() => {window.location = response.data.next_url}, 1000);

                            }else{
                                this.response_error_message = response.data.message;
                                this.response_success_message = '';
                            }

                            this.hideMessagesResponse();

                        }).catch( (error) => {
                            this.response_error_message = 'Ocurrió un error, intente de nuevo.';

                            this.hideMessagesResponse();
                        });
                    }
                });
            },

            hideMessagesResponse: function(){
                setTimeout(function () {
                    this.response_error_message = '';
                    this.response_success_message = '';
                }.bind(this), 2000)
            }
        },

        mounted() {
            this.$nextTick(() => {
                if (this.data_member_1 && this.data_member_2){
                    this.$validator.validateAll();
                }
            });
        }
    }
</script>
